import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import GranPumpView from '../views/GranPumpView.vue'
import GranFlowView from '../views/GranFlowView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/granpump',
    name: 'granpump',
    component: GranPumpView,
    //meta: { transition: 'slide-right' },
  },
  {
    path: '/granflow',
    name: 'granflow',
    component: GranFlowView,
    //meta: { transition: 'slide-right' },
  }
  /*{
    path: '/granpump',
    name: 'granpump',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about"  '../views/GranPumpView.vue')
  }*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
