<template>
  <div class="h-100">
    <!--<h1>Насосное оборудование АДЛ</h1>-->
    <v-row class="h-100" justify="center" align="center" no-gutters >
      <v-col xl="3" lg="4" md="5">
        <v-card>
          <v-card-item>
              <v-card-title><h1 class="text-center text-h4">Подбор</h1></v-card-title>

              <v-card-subtitle></v-card-subtitle>
            </v-card-item>
            <v-card-text>
              <v-btn @click="$router.push('/granpump/')" variant="tonal" :size="!$vuetify.display.xs ? 'large' :'small'" block color="var(--root-color)">Насосы ГРАНПАМП</v-btn>
              <v-btn @click="$router.push('/granflow/')"  variant="tonal" :size="!$vuetify.display.xs ? 'large' :'small'" block class="mt-4" color="var(--root-color)">Насосные установки ГРАНФЛОУ</v-btn>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


// Components
//import HelloWorld from '../components/HelloWorld.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    //HelloWorld,
  },

  mounted() {
    //console.log(this.$vuetify.display.xs)
  },
});
</script>
<style scoped>

</style>