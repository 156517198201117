<template>
  <GranFlowSelect/>
</template>
<script lang='ts'>
import { defineComponent } from 'vue'
import GranFlowSelect from '@/components/GranFlowSelect.vue'


export default defineComponent({
  components:{
    GranFlowSelect
  },
  data () {
    return {
      
    }
  },
})
</script>