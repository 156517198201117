<template>
  <GranPumpSelect/>
</template>
<script lang='ts'>
import { defineComponent } from 'vue'
import GranPumpSelect from '@/components/GranPumpSelect.vue'


export default defineComponent({
  components:{
    GranPumpSelect
  },
  data () {
    return {
      
    }
  },
})
</script>